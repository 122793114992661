import { ArrowRightIcon } from 'components/Icons/ArrowRightIcon'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { AtomButton } from '../../atoms/AtomButton'
import { BootstrapCols } from '../../commons/BootstrapCols'
import { ContainerFluid } from '../../commons/Container'
import { Grid } from '../../commons/Grid'
import { StyledH3, StyledHTMLTags } from '../../commons/Theme'
import { CloseIcon } from '../Icons/CloseIcon'
import { Loader } from './components/Loading'
import { SimpleHeader } from 'components/Header/SimpleHeader'
import { StyledButtonWrapper } from './RicaricaForm.style'
import { isValidEmail, removeTrailingSlashes } from 'commons/utils'
import { Input } from 'commons/FormComponents/Input'
import { useFormErrors } from 'hooks/use-form-errors'

type PostazioneType = {
  postazione: string
  nome: string
  cognome: string
  cellulare: string
  email: string
  data_inserimento: string
}

export const RicercaPostazioneForm = () => {
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [postazioni, setPostazioni] = useState<PostazioneType[]>([])
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { errors, setErrors, clearError, resetAllErrors, scrollToErrors } =
    useFormErrors()

  function validateForm() {
    resetAllErrors()

    const errorsForm: Record<string, string> = {}

    if (surname.length === 0) {
      Object.assign(errorsForm, { surname: 'Campo obbligatorio' })
    }

    if (email.length === 0) {
      Object.assign(errorsForm, { email: 'Campo obbligatorio' })
    }

    if (email.length > 0 && !isValidEmail(email)) {
      Object.assign(errorsForm, { email: 'Email non valida' })
    }

    if (phone.length === 0) {
      Object.assign(errorsForm, { phone: 'Campo obbligatorio' })
    }

    setErrors(errorsForm)

    const isFormValid = Object.entries(errorsForm).length === 0

    scrollToErrors(errorsForm)

    return isFormValid
  }

  function handleOnCerca() {
    const isValidForm = validateForm()
    if (!isValidForm) {
      return
    }

    setLoading(true)
    setPostazioni([])
    setError('')

    fetch(
      `${removeTrailingSlashes(
        process.env.A2A_DRUPAL_CASA_URL
      )}/a2a-api/postazioni-ricarica-filter`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXNzd29yZCI6InojT2RsRTFKU3V4eXUmdVhlUl4mQTlkbm1vRkohaHVtbDNqVlRBOFRIY1kmeW9ASkhYIn0.riPIcy0C_FUY3XfzaIyxepkVITGwWxD71eC4bRVj6j4`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cognome: surname,
          cellulare: phone,
          email: email,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (Array.isArray(res)) {
          res.length === 0 && setError('Nessuna postazione trovata.')
          setPostazioni(res)
        }
      })
      .catch((err) => {
        console.error(err)
        setError('Nessuna postazione trovata.')
      })
      .finally(() => setLoading(false))
  }

  function handleOnReset() {
    setSurname('')
    setEmail('')
    setPhone('')
    setError('')
    setPostazioni([])
  }

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <SimpleHeader />
      <ContainerFluid style={{ marginTop: '120px', marginBottom: '120px' }}>
        <BootstrapCols cols={10}>
          <Grid cols={1} tabletCols={2} rowsGap="40px">
            <Input
              id="surname"
              label="Cognome"
              value={surname}
              onChange={(e) => {
                clearError('surname')
                setSurname(e)
              }}
              onFocus={() => clearError('surname')}
              placeholder="Cognome"
              error={errors.surname}
            />
            <Input
              id="email"
              label="Email"
              value={email}
              onChange={(e) => {
                clearError('email')
                setEmail(e)
              }}
              onFocus={() => clearError('email')}
              placeholder="Email"
              error={errors.email}
            />
            <Input
              id="phone"
              label="Cellulare"
              value={phone}
              onChange={(e) => {
                clearError('phone')
                setPhone(e)
              }}
              onFocus={() => clearError('phone')}
              placeholder="Cellulare"
              error={errors.phone}
            />
          </Grid>
          <StyledButtonWrapper>
            <AtomButton
              styleType="ghost"
              label="Reset ricerca"
              onClick={handleOnReset}
              icon={<CloseIcon />}
              iconPosition="right"
            />
            {loading ? (
              <Loader />
            ) : (
              <AtomButton
                label="Ricerca postazione"
                onClick={handleOnCerca}
                icon={<ArrowRightIcon />}
                iconPosition="right"
              />
            )}
          </StyledButtonWrapper>
          {error && <StyledH3>{error}</StyledH3>}
        </BootstrapCols>
        {postazioni.length > 0 && (
          <StyledHTMLTags>
            <table width="100%">
              <thead>
                <tr>
                  <th>Postazione</th>
                  <th>Nome</th>
                  <th>Cognome</th>
                  <th>Email</th>
                  <th>Cellulare</th>
                  <th>Data inserimento</th>
                </tr>
              </thead>
              <tbody>
                {postazioni.map((it: PostazioneType, i) => (
                  <tr key={i}>
                    <td>
                      <strong>{it.postazione}</strong>
                    </td>
                    <td>{it.nome}</td>
                    <td>{it.cognome}</td>
                    <td>{it.email}</td>
                    <td>{it.cellulare}</td>
                    <td>{it.data_inserimento}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </StyledHTMLTags>
        )}
      </ContainerFluid>
    </div>
  )
}
