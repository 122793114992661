import styled from 'styled-components'
import { Colors } from '../../../commons/Theme'
import { LoadingIcon } from 'components/Icons/LoadingIcon'

export const LoaderWrapper = styled.div`
  width: 40px;
  margin: 0 100px;
  svg {
    animation: spin 2s linear infinite;
    fill: ${Colors.azure600};
    width: 100%;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Loader = () => {
  return (
    <LoaderWrapper>
      <LoadingIcon />
    </LoaderWrapper>
  )
}
