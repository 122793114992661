export const LoadingIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 66 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19.0984 32.9642L18.167 25.3499L24.1276 21.2642L16.3043 10.957C10.4369 14.857 5.03501 24.8856 6.33889 30.6427C10.3437 31.757 19.0984 32.9642 19.0984 32.9642Z" />
      <path d="M6.33888 33.707L19.0052 35.8427L24.1276 40.9499C24.1276 40.9499 20.216 48.0999 18.5395 51.0713C12.7652 48.9356 6.24575 40.7642 6.33888 33.707Z" />
      <path d="M26.5491 43.1785L36.3282 41.2285L42.7545 53.2071C42.7545 53.2071 31.4852 56.9214 24.2207 54.7856C25.3384 47.2642 26.5491 43.1785 26.5491 43.1785Z" />
      <path d="M41.2651 38.35L42.6621 36.8643L53.2794 42.4357L50.3923 48.1L41.2651 38.35Z" />
      <path d="M45 35.7V29.3857L55.0586 24L55.9899 39.8785L45 35.7Z" />
      <path d="M41 22.007L43.049 24.8856L59.6269 15.8785L55.9015 12.0713L41 22.007Z" />
      <path d="M20.5889 8.63598L27.574 18.4788L32.6033 16.4359L37.6325 20.3359L47.4116 12.7217C47.3185 12.7217 38.3776 0.557406 20.5889 8.63598Z" />
      <path d="M35.209 59.8928L36.1404 64.9999C36.1404 64.9999 47.3165 63.0499 53.2771 58.7785C51.8801 56.1785 50.6693 54.2285 50.6693 54.2285L44.2431 59.6142L35.209 59.8928Z" />
      <path d="M54.2085 51.1646L58.772 54.2289L63.1494 46.5217L58.6789 45.1289L54.2085 51.1646Z" />
      <path d="M59.8901 27.6716C60.4489 31.0145 60.8214 37.9787 59.8901 40.393C61.7528 41.1359 64.7331 42.4359 64.7331 42.4359C64.7331 42.4359 67.2477 34.6359 64.7331 26.8359C62.3116 27.393 59.8901 27.6716 59.8901 27.6716Z" />
      <path d="M58.6782 15.0428L60.2615 18.9428L63.9868 17.7357L62.5898 13L58.6782 15.0428Z" />
      <path d="M41.2651 5.94261L42.6621 3.52832L54.2108 7.79975L49.6472 11.0498L41.2651 5.94261Z" />
      <path d="M17.7012 2.69286L32.8821 0L37.7251 0.928571L36.8869 3.52857L33.4409 2.69286L19.2845 5.29286L17.7012 2.69286Z" />
      <path d="M9.97093 9.09961L11.4611 10.0282L5.03481 21.1711H0.84375L9.97093 9.09961Z" />
      <path d="M0.378691 28.3211C-1.01833 37.6068 1.7757 44.3854 2.70705 45.1282L5.50108 44.8497C5.50108 44.8497 2.33451 33.7997 1.68257 28.0425L0.378691 28.3211Z" />
      <path d="M5.59375 51.6286L8.38779 49.3071C8.38779 49.3071 13.2308 54.2286 18.7258 56.4571C17.515 58.7786 17.0493 59.5214 17.0493 59.5214C17.0493 59.5214 9.03973 55.8071 5.59375 51.6286Z" />
      <path d="M22.3569 58.4072V61.9357L30.2733 64.2572L30.739 59.6143L22.3569 58.4072Z" />
    </svg>
  )
}
