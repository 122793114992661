import { RicercaPostazioneForm } from '../components/RicaricaForm/RicercaPostazioneForm'
import { A2ADataContextProvider } from '../contexts/A2ADataContext'
import { LayoutContextProvider } from '../contexts/LayoutContext'

const RicercaPostazionePage = () => {
  return (
    <A2ADataContextProvider>
      <LayoutContextProvider>
        <RicercaPostazioneForm />
      </LayoutContextProvider>
    </A2ADataContextProvider>
  )
}

export default RicercaPostazionePage
