import styled from 'styled-components'
import { BPSizes, ColumnsGap } from './Theme'

const StyledGrid = styled.div<StyledGridProps>`
  display: grid;
  grid-template-columns: ${({ $cols }) => $cols};
  grid-auto-rows: ${({ $gridAutoRows }) => $gridAutoRows};
  grid-column-gap: ${({ $colsGap }) => $colsGap};
  grid-row-gap: ${({ $rowsGap }) => $rowsGap};
  grid-template-rows: ${({ $rows }) => $rows};

  @media screen and (min-width: ${BPSizes.tablet}px) {
    grid-template-columns: ${({ $tabletCols }) => $tabletCols};
    grid-template-rows: ${({ $tabletRows }) => $tabletRows};
    grid-column-gap: ${({ $colsTabletGap }) => $colsTabletGap};
    grid-row-gap: ${({ $rowsTabletGap }) => $rowsTabletGap};
  }
  @media screen and (min-width: ${BPSizes.desktop}px) {
    grid-template-columns: ${({ $desktopCols }) => $desktopCols};
    grid-template-rows: ${({ $desktopRows }) => $desktopRows};
    grid-column-gap: ${({ $colsDesktopGap }) => $colsDesktopGap};
    grid-row-gap: ${({ $rowsDesktopGap }) => $rowsDesktopGap};
  }
  @media screen and (min-width: ${BPSizes.large}px) {
    grid-template-columns: ${({ $largeCols }) => $largeCols};
    grid-template-rows: ${({ $largeRows }) => $largeRows};
    grid-column-gap: ${({ $colsLargeGap }) => $colsLargeGap};
    grid-row-gap: ${({ $rowsLargeGap }) => $rowsLargeGap};
  }
`

type StyledGridProps = {
  $cols: string
  $colsGap: string
  $colsTabletGap: string
  $colsDesktopGap: string
  $colsLargeGap: string
  $rowsGap: string
  $rowsTabletGap: string
  $rowsDesktopGap: string
  $rowsLargeGap: string
  $tabletCols: string
  $desktopCols: string
  $largeCols: string
  $gridAutoRows: string
  $rows: string
  $tabletRows: string
  $desktopRows: string
  $largeRows: string
}

function calculateGridTemplate(cols: Row) {
  return typeof cols === 'number' ? `repeat(${cols}, minmax(10px, 1fr))` : cols
}

export function Grid({
  cols,
  colsGap = ColumnsGap.sm,
  colsTabletGap = ColumnsGap.md,
  colsDesktopGap = ColumnsGap.lg,
  colsLargeGap = ColumnsGap.xlg,
  rowsGap = '24px',
  rowsTabletGap = rowsGap,
  rowsDesktopGap = rowsTabletGap,
  rowsLargeGap = rowsDesktopGap,
  tabletCols,
  desktopCols,
  largeCols,
  sameRows = false,
  rows,
  tabletRows,
  desktopRows,
  largeRows,
  children,
  ...rest
}: GridProps) {
  const defaultGridTemplateColumns = calculateGridTemplate(cols)
  const tabletGridTemplateColumns = calculateGridTemplate(
    tabletCols || defaultGridTemplateColumns
  )
  const desktopGridTemplateColumns = calculateGridTemplate(
    desktopCols || tabletGridTemplateColumns
  )
  const largeGridTemplateColumns = calculateGridTemplate(
    largeCols || desktopGridTemplateColumns
  )
  const gridAutoRows = sameRows ? '1fr' : 'auto'

  const defaultGridTemplateRows = rows ? calculateGridTemplate(rows) : ''
  const tabletGridTemplateRows = calculateGridTemplate(
    tabletRows || defaultGridTemplateRows
  )
  const desktopGridTemplateRows = calculateGridTemplate(
    desktopRows || tabletGridTemplateRows
  )
  const largeGridTemplateRows = calculateGridTemplate(
    largeRows || desktopGridTemplateRows
  )

  return (
    <StyledGrid
      $cols={defaultGridTemplateColumns}
      $tabletCols={tabletGridTemplateColumns}
      $desktopCols={desktopGridTemplateColumns}
      $largeCols={largeGridTemplateColumns}
      $colsGap={colsGap}
      $colsTabletGap={colsTabletGap}
      $colsDesktopGap={colsDesktopGap}
      $colsLargeGap={colsLargeGap}
      $rowsGap={rowsGap}
      $rowsTabletGap={rowsTabletGap}
      $rowsDesktopGap={rowsDesktopGap}
      $rowsLargeGap={rowsLargeGap}
      $gridAutoRows={gridAutoRows}
      $rows={defaultGridTemplateRows}
      $tabletRows={tabletGridTemplateRows}
      $desktopRows={desktopGridTemplateRows}
      $largeRows={largeGridTemplateRows}
      {...rest}
    >
      {children}
    </StyledGrid>
  )
}

type Row = string | number

export type GridProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode
  cols: Row
  tabletCols?: Row
  desktopCols?: Row
  largeCols?: Row
  colsGap?: string
  colsTabletGap?: string
  colsDesktopGap?: string
  colsLargeGap?: string
  rowsGap?: string
  rowsTabletGap?: string
  rowsDesktopGap?: string
  rowsLargeGap?: string
  sameRows?: boolean
  rows?: Row
  tabletRows?: Row
  desktopRows?: Row
  largeRows?: Row
  onMouseLeave?: () => void
}

export const Grid12 = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: ${ColumnsGap.sm};
  row-gap: 24px;
  @media screen and (min-width: ${BPSizes.md}px) {
    grid-template-columns: repeat(6, 1fr);
    column-gap: ${ColumnsGap.md};
  }
  @media screen and (min-width: ${BPSizes.lg}px) {
    grid-template-columns: repeat(12, 1fr);
    column-gap: ${ColumnsGap.lg};
  }
`

export function GridMain(props: { cols?: Row } & Omit<GridProps, 'cols'>) {
  const {
    cols = 4,
    tabletCols = 6,
    desktopCols = 12,
    largeCols = 12,
    ...rest
  } = props

  return (
    <Grid
      cols={cols}
      tabletCols={tabletCols}
      desktopCols={desktopCols}
      largeCols={largeCols}
      {...rest}
    />
  )
}
