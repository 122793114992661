import { ReactNode, createContext } from 'react'

interface LayoutContextType {}

export const LayoutContext = createContext<LayoutContextType>(
  {} as LayoutContextType
)

export const LayoutContextProvider = (props: { children: ReactNode }) => {
  return (
    <LayoutContext.Provider value={{}}>{props.children}</LayoutContext.Provider>
  )
}
