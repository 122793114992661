import { randomString } from 'commons/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const useFormErrors = (args?: { formPrefix?: string }) => {
  const [formId, setFormId] = useState<string>(
    `${args?.formPrefix}-${randomString(5)}`
  )
  const [errors, setErrors] = useState<Record<string, string>>({})

  const isFormValid = useMemo(() => Object.keys(errors).length === 0, [errors])

  const clearError = (error: string) => {
    setErrors((d) => {
      delete d[error]
      return { ...d }
    })
  }

  const resetAllErrors = useCallback(() => {
    setErrors({})
  }, [])

  const addError = (error: string, message: string) => {
    setErrors((d) => {
      d[error] = message
      return { ...d }
    })
  }

  const scrollToError = (errorKey?: string, formContainerEl?: HTMLElement) => {
    if (errorKey) {
      const element = document.getElementById(errorKey)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    } else if (errors) {
      const errorKeyValue = Object.entries(errors).find(([, value]) => !!value)
      if (errorKeyValue) {
        const [key] = errorKeyValue
        const errorInputNode =
          document.getElementById(key) ||
          document.getElementById(`${key}-${formId}`)
        if (errorInputNode) {
          const topOffset = errorInputNode.getBoundingClientRect().top

          ;(formContainerEl || window).scrollBy({
            behavior: 'smooth',
            top: topOffset - 100,
          })
        }
      }
    }
  }

  const scrollToErrors = (
    errors: Record<string, string>,
    formContainerEl?: HTMLElement
  ) => {
    const errorKeyValue = Object.entries(errors).find(([, value]) => !!value)
    if (errorKeyValue) {
      const [key] = errorKeyValue
      const errorInputNode =
        document.getElementById(key) ||
        document.getElementById(`${key}-${formId}`)
      if (errorInputNode) {
        const topOffset = errorInputNode.getBoundingClientRect().top

        ;(formContainerEl || window).scrollBy({
          behavior: 'smooth',
          top: topOffset - 100,
        })
      }
    }
  }

  useEffect(() => {
    if (args?.formPrefix) {
      setFormId(`${args.formPrefix}-${randomString(5)}`)
    }
  }, [args?.formPrefix])

  return useMemo(
    () => ({
      formId,
      isFormValid,
      errors,
      addError,
      setErrors,
      clearError,
      resetAllErrors,
      scrollToError,
      scrollToErrors,
    }),
    [errors, formId]
  )
}
