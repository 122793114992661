import { StyledHTMLTags } from 'commons/Theme'
import styled from 'styled-components'
import { BPSizes, Colors, Rem } from '../../commons/Theme'

export const StyledRicaricaMessaggioTitle = styled.div`
  font-size: ${Rem(28)};
  color: ${Colors.azure600};
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 1.2;
  @media screen and (min-width: ${BPSizes.desktop}px) {
    font-size: ${Rem(40)};
  }
`

export const StyledRadioGroup = styled.div<{ $hasFooter?: boolean }>`
  display: flex;
  gap: 40px;
  margin-top: 8px;
  margin-bottom: ${({ $hasFooter }) => ($hasFooter ? '8px' : '40px')};
`

export const StyledPostazioneLabel = styled.div`
  margin: 40px 0 32px;
  font-size: ${Rem(16)};
  color: ${Colors.grey900};
  font-weight: 600;
`

export const StyledPostazioneInput = styled.input`
  background: ${Colors.grey100};
  color: ${Colors.black};
  font-size: ${Rem(32)};
  font-weight: 400;
  text-align: center;

  height: auto;
  width: 120px;
  padding: 16px;

  outline: none;
  border: none;
  border: 1px solid ${Colors.grey200};
  border-radius: 8px;
  &:focus {
    border-color: ${Colors.azure600};
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${Colors.grey500};
  }
`
export const StyledPostazioneRicarica = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  margin-left: auto;
`

export const StyledSubmitButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  svg {
    width: 64px;
    stroke: ${Colors.white};
    @media screen and (min-width: ${BPSizes.tablet}px) {
      width: 80px;
    }
    @media screen and (min-width: ${BPSizes.desktop}px) {
      width: 120px;
    }
  }
`

export const StyledInfoText = styled.div<{ $hasMargin?: boolean }>`
  flex: 1;
  margin: 0;
  font-size: ${Rem(16)};
  color: ${Colors.grey900};
  font-weight: 400;
  margin-bottom: ${({ $hasMargin }) => ($hasMargin ? '40px' : '0px')};
  a {
    text-decoration: none;
    color: ${Colors.azure600};
    &:hover {
      color: ${Colors.blue400};
    }
  }

  input {
    width: 150px;
    padding: 0 12px;
    margin: 0 12px;
    outline: none;
    border: none;
    border-bottom: 1px solid ${Colors.grey200};
    &::placeholder {
      font-size: ${Rem(12)};
      color: ${Colors.grey500};
    }
    &:focus {
      border-color: ${Colors.azure600};
      &::placeholder {
        color: transparent;
      }
    }
  }
`

export const StyledPrivacyModalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
`

export const StyledPrivacyHTML = styled(StyledHTMLTags)`
  scrollbar-width: 4px;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${Colors.grey300};
    border-radius: 999px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
`

export const StyledTYPContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${Colors.azure600};
`

export const StyledTYPBody = styled.div`
  flex: 1;
  gap: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0 120px;
  position: relative;
  @media screen and (min-width: ${BPSizes.desktop}px) {
    padding: 80px 0;
  }
`

export const StyledButtonWrapper = styled.div`
  margin: 80px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 50px;
  gap: 28px;
  flex-direction: column;

  @media screen and (min-width: ${BPSizes.tablet}px) {
    flex-direction: row;
  }
`
