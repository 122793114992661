import styled from 'styled-components'
import { BPSizes } from './Theme'

const StyledBootstrapCols = styled.div<{
  $hasMaxWidth?: boolean
  $cols?: number
  $tabletCols?: number
  $desktopCols?: number
  $largeCols?: number
  $exactWidth?: boolean
}>`
  margin: 0 auto;
  max-width: ${({ $hasMaxWidth }) => ($hasMaxWidth ? '1920px' : 'initial')};
  width: ${({ $cols, $exactWidth }) =>
    $cols &&
    `min(${
      $exactWidth ? '' : '100%,'
    }calc((100vw - 16px) / 12 * ${$cols} - 16px / 12 * ${12 - $cols}))`};

  @media screen and (min-width: ${BPSizes.tablet}px) {
    width: ${({ $tabletCols, $exactWidth }) =>
      $tabletCols &&
      `min(${
        $exactWidth ? '' : '100%,'
      }calc((100vw - 32px) / 12 * ${$tabletCols} - 32px / 12 * ${
        12 - $tabletCols
      }))`};
  }

  @media screen and (min-width: ${BPSizes.desktop}px) {
    width: ${({ $desktopCols, $exactWidth }) =>
      $desktopCols &&
      `min(${
        $exactWidth ? '' : '100%,'
      }calc((100vw - 32px) / 12 * ${$desktopCols} - 32px / 12 * ${
        12 - $desktopCols
      }))`};
  }

  @media screen and (min-width: ${BPSizes.large}px) {
    width: ${({ $largeCols, $exactWidth }) =>
      $largeCols &&
      `min(${
        $exactWidth ? '' : '100%,'
      }calc((100vw - 32px) / 12 * ${$largeCols} - 32px / 12 * ${
        12 - $largeCols
      }))`};
  }
`

/**
 * BootstrapCols
 * @deprecated Needs refactor based on new layout
 */
export const BootstrapCols = ({
  cols = 12,
  tabletCols = cols,
  desktopCols = tabletCols,
  largeCols = desktopCols,
  hasMaxWidth = true,
  children,
  exactWidth = false,
  ...rest
}: BootstrapColsType) => {
  return (
    <StyledBootstrapCols
      $cols={cols}
      $tabletCols={tabletCols}
      $desktopCols={desktopCols}
      $largeCols={largeCols}
      $hasMaxWidth={hasMaxWidth}
      $exactWidth={exactWidth}
      {...rest}
    >
      {children}
    </StyledBootstrapCols>
  )
}

type BootstrapColsType = React.HTMLAttributes<HTMLDivElement> & {
  cols?: number
  tabletCols?: number
  desktopCols?: number
  largeCols?: number
  hasMaxWidth?: boolean
  children: React.ReactNode
  exactWidth?: boolean
}
